







































































































































































































































































































































































































































































































































































































































































































































import { Vue, Component, Watch } from 'vue-class-decorator'
import { GlobalModule, PricesModule, UserModule } from '@/store'
import { Payload } from 'vue/interfaces'

@Component({
  metaInfo: {
    title: 'Все объекты'
  }
})
export default class Prices extends Vue {
    public tablePrice: Array<object>
    public priceDialog: boolean
    public dialogKey: boolean
    public excelLoaderDialog: boolean
    public excelFile: object | null
    public viewPriceObject: { [propName: string]: any } | undefined
    public selectedPriceObjects: Array<object>
    public search: string
    public filterManager: string | null
    public filterPriceMin: number | string
    public filterPriceMax: number | string

    // Presentation
    public presentationDialog: boolean
    public presentationAddress: string
    public presentationPriceId: number | null
    public presentationPrice: string
    public km: string
    public kvm: string
    public kvmM: string
    public sotok: string
    public material: string
    public transport: string
    public presentationInfrastructure: string
    public communications: string
    public presentationImage: object | null

    // Data
    constructor() {
      super()
      this.priceDialog = false
      this.dialogKey = false
      this.excelLoaderDialog = false
      this.viewPriceObject = {}
      this.selectedPriceObjects = []
      this.excelFile = null
      this.search = ''
      this.filterManager = ''
      this.filterPriceMin = ''
      this.filterPriceMax = ''
      this.tablePrice = [
        {
          text: 'ID',
          value: 'price_id',
          align: 'start',
          sortable: false
        },
        {
          text: 'Название',
          value: 'name',
          sortable: false
        },
        {
          text: 'Тип',
          value: 'type_name',
          sortable: false
        },
        {
          text: 'Адрес',
          value: 'adress',
          sortable: false
        },
        {
          text: 'Цена объекта',
          value: 'price',
          sortable: false,
          filter: value => this.getFilterPrice(value)
        },
        // {
        //   text: 'Менеджеры',
        //   value: 'users_titles',
        //   sortable: false,
        //   align: 'center',
        //   filter: value => this.getFilterManagers(value)
        // },
        {
          text: 'Клиенты',
          value: 'clients_titles',
          align: 'center',
          sortable: false
        },
        {
          text: 'Статус',
          value: 'object_status_name',
          sortable: false
        },
        {
          text: 'Реклама',
          value: 'sale_status_name',
          sortable: false
        },
        {
          text: 'Информация',
          value: 'info',
          align: 'center',
          sortable: false
        }
      ]

      // Presentation
      this.presentationDialog = false
      this.presentationAddress = ''
      this.presentationPriceId = null
      this.presentationPrice = ''
      this.km = ''
      this.kvm = ''
      this.kvmM = ''
      this.sotok = ''
      this.material = ''
      this.transport = ''
      this.presentationInfrastructure = ''
      this.communications = ''
      this.presentationImage = null
    }

  // Store init
  @UserModule.Getter('userInfo') user!: {[propName: string]: any }
  @GlobalModule.Getter('getLoading') loading!: boolean
  @PricesModule.Getter('getPrices') objectsList!: Array<object>
  @PricesModule.Getter('getManagers') managers!: Array<string>
  @PricesModule.Action('requestPriceObjects') requestPriceObjects!: () => Promise<any>
  @PricesModule.Action('deleteArrayObject') deleteArrayObject!: (arr: Array<number | string>) => Promise<any>
  @PricesModule.Action('loadExcelFileToServer') loadExcelFileToServer!: (file: object) => Promise<any>
  @PricesModule.Action('unloadExcelFileFromServer') unloadExcelFileFromServer!: (ids: Array<number> | null) => Promise<any>
  @PricesModule.Action('getPresentation') getPresentation!: (payload: Payload) => Promise<any>
  @PricesModule.Action('addToMyPrices') addToMyPricesStore!: (payload: Payload) => Promise<any>
  @PricesModule.Action('deleteFromMyPrices') deleteFromMyPricesStore!: (payload: Payload) => Promise<any>

  // Watch
  @Watch('priceDialog') clearModalFields() {
    if (!this.priceDialog) {
      this.viewPriceObject = {}
      setTimeout(() => {
        this.dialogKey = !this.dialogKey
      }, 200)
    }
  }

  viewPriceObejct(id: number) {
    const find = this.objectsList.find((item: any) => item.price_id === id) as { [propName: string]: any }
    // eslint-disable-next-line
    find.info.clients_titles = find.clients_titles
    this.viewPriceObject = find.info
    this.priceDialog = true
  }

  editObject(id: number) {
    this.$router.push(`/prices/edit/${id}`)
  }

  createObject() {
    this.$router.push('/prices/create')
  }

  getIDSelecteditems() {
    if (this.selectedPriceObjects && this.selectedPriceObjects.length > 0) {
      const ids = this.selectedPriceObjects.map((item: any) => item.price_id)
      return ids
    } else {
      const ids = this.objectsList.map((item: any) => item.price_id)
      return ids
    }
  }

  disableAdsSelectedItems() {
    const ids = this.getIDSelecteditems()
  }

  getFilterManagers(value) {
    if (!this.filterManager) return true

    return value.includes(this.filterManager)
  }

  getFilterPrice(value) {
    const min = this.filterPriceMin !== '' ? Number(value) >= Number(this.filterPriceMin) : true
    const max = this.filterPriceMax !== '' ? Number(value) <= Number(this.filterPriceMax) : true

    return min && max
  }

  deleteFromMyPrices(priceId) {
    this.deleteFromMyPricesStore(priceId)
      .then(() => {
        this.$noty('success', 'Вы успешно отписались от объекта.', 7000).show()
        this.requestPriceObjects()
          .catch((error) => {
            this.$noty('error', error.message, 7000).show()
          })
      })
      .catch(() => {
        this.$noty('error', 'Произошла непредвиденная ошибка, возможно отсутствует доступ.', 7000).show()
      })
  }

  addToMyPrices(priceId) {
    this.addToMyPricesStore(priceId)
      .then(() => {
        this.$noty('success', 'Вы успешно подписались на этот объект.', 7000).show()
        this.requestPriceObjects()
          .catch((error) => {
            this.$noty('error', error.message, 7000).show()
          })
      })
      .catch(() => {
        this.$noty('error', 'Произошла непредвиденная ошибка, возможно отсутствует доступ.', 7000).show()
      })
  }

  async unloadXlsx() {
    await this.unloadExcelFileFromServer(this.getIDSelecteditems())
      .then((res) => {
        this.$noty('success', res.data.success, 7000).show()
        window.open(res.data.file, '_blank')
      })
      .catch((error) => {
        this.$noty('error', error.message, 7000).show()
      })
  }

  async loadExcelToServerAndStartSycnProcess() {
    const file = this.excelFile || {}
    await this.loadExcelFileToServer(file)
      .then((res) => {
        this.$noty('success', res.data.success, 7000).show()
        this.excelFile = null

        this.requestPriceObjects()
          .catch((error) => {
            this.$noty('error', error.message, 7000).show()
          })
      })
      .catch((error) => {
        this.$noty('error', error.message, 7000).show()
        this.excelFile = null
      })
  }

  openPresentationDialog(object) {
    this.presentationAddress = object.adress
    this.presentationPriceId = object.price_id
    this.presentationPrice = object.price
    this.km = object.info.city
    this.kvm = object.info.common_meters
    this.kvmM = object.info.size_house
    this.sotok = object.info.territory_document
    this.material = object.info.home
    this.transport = object.info.transport_reach
    this.presentationInfrastructure = object.info.infrastructure
    this.communications = `Газ: ${object.info.gas}, электричество: ${object.info.electro}, вода: ${object.info.water}, канализация: ${object.info.sewerage}`
    this.presentationImage = null

    this.presentationDialog = true
  }

  toBase64(file) {
    return new Promise((resolve, reject) => {
      if (!file) resolve(null)

      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }

  async generatePresentation() {
    const payload = {
      priceId: this.presentationPriceId,
      address: this.presentationAddress,
      price: this.presentationPrice,
      km: this.km,
      kvm: this.kvm,
      kvmM: this.kvmM,
      sotok: this.sotok,
      material: this.material,
      communications: this.communications,
      transport: this.transport,
      infrastructure: this.presentationInfrastructure,
      image: await this.toBase64(this.presentationImage)
    }

    this.getPresentation(payload)
      .then((res) => {
        this.$noty('success', 'Презентация сгенерирована!', 7000).show()
        this.presentationDialog = false
        window.open(res.data.file, '_blank')
      })
      .catch((error) => {
        this.$noty('error', error.message, 7000).show()
      })
  }

  deleteSelectedItems() {
    const ids = this.getIDSelecteditems()

    this.deleteArrayObject(ids)
      .then(() => {
        this.$noty('success', 'Объекты успешно удалены', 7000).show()
      })
      .catch((error) => {
        this.$noty('error', error.message, 7000).show()
      })
  }

  created() {
    this.requestPriceObjects()
      .catch((error) => {
        this.$noty('error', error.message, 7000).show()
      })
  }
}
